import {Observable} from 'rxjs';

function toJSON<T>(): Observable<T> {
    return this.map(( response: Response ) => response.json() || {});
}

// Observable.prototype.toJSON = toJSON;

/*declare module 'rxjs/Observable' {
    interface Observable<T> {
        toJSON: typeof toJSON;
    }
}*/
